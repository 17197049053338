import React from "react";

function About() {
  return (
    <div className="about__description">
      <p>
        <b>
        I’m a scientist, communicator and educator.
      </b>
      </p>
      <p>
        <b>
        As evolutionary biologist, I study how species evolve and adapt to
        environmental change, as writter and educator I focus on systemic 
        socio-ecological issues and how they shape our relationship with each other and nature.
        </b>
      </p>
      <p>
          I was born and raised in Portugal, and my research in evolution & genomics 
          took me across 5 continents, where I learned biodiversity from different 
          species and within different communities. 
          Reptiles hold a special wonder-full place in my heart!   
      </p>
      <p>
        <b>
          In 2019, I founded evALLution: an education project to make evolution
          and biodiversity accessible for, and with, people with blindness. This woke up a love for multisensory education, 
          and ideas of biodiversity knowledge as path towards belonging. 
        </b>
      </p>
      <p>
        I’m a first-generation high school graduate, and believe that a more
        inclusive and holistic education system is key for a sustainable and
        equitable future.
      </p>
      <p>
        <b>
          Currently, I'm working on designing culturaly-responsive
          education resources that foster complex systemic thinking and kindle a 
          true sense of shared ancestry with and belonging to all life on Earth.
        </b>
      </p>
    </div>
  );
}

export default About;
