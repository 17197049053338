const students = [
  {
    id: 1,
    name: "student of 'The Environment and the Self' 2021",
    description:
      "The practice of sitting in discomfort and removing antagonization from the conversation allowed me to unleash a power in my environmentalism that has brought me closer to my goal of being a person for the people. My spark was reignited and is flourishing again, in great thanks to this class",
  },
  {
    id: 2,
    name: "Dr. Francisco Pina-Martins, teacher at FCUL & IPS",
    description:
      "Inviting Telma to teach in my classes was the greatest decision I could have made. The workshop she created was dynamic, with a perfectly balanced learning curve and with a superb level of student engagement. The students learnt a new skill from scratch and this high morale was perfectly reflected in their evaluation! 10/10 would invite again.",
  },
  {
    id: 3,
    name: "MSc student 2019",
    description:
      "She could always explain complicated matters in very clear ways. Her enthusiasm for science is contagious and motivated me to always dig a bit deeper and to question everything. I absolutely loved having her as my supervisor and thanks to her support and knowledge I could finish my masters with joy.",
  },
  {
    id: 4,
    name: "Participant: 'Mycellial creativity' a depth education workshop in socio-ecology 2024",
    description:
      "Thank you for the introspective exercise and for the trip to the ecologies of others. It was so good and surprising (...) Sharing spaces like these are so interesting and important.",
  },
  {
    id: 5,
    name: "student of 'The Environment and the Self' 2021",
    description:
      "Every class, homework assignment, and reading allowed me to explore myself and my place in the world. I feel much more grounded as an individual and plan to use the many lessons I learned in this class to lead me in a direction that feels true to me.",
  },
  {
    id: 6,
    name: "MSc student 2018",
    description:
      "I really appreciated Telma's openness and availability for my doubts and questions. She was always enthusiastic, encouraging and could clarify even the more abstract matters of biology.",
  },
  {
    id: 7,
    name: "student of 'The Environment and the Self' 2021",
    description:
      "Thank you so much for granting us the libratory agency to forge meaningful wisdoms within ourselves, with the natural world, and with each other. This class truly initiated a lifetime pursuit of meaningful and holistic understanding and I will be forever grateful for the incredible and illuminating space you created for us.",
  },
  {
    id: 8,
    name: "student of 'The Environment and the Self' 2021",
    description:
      "With the exception of this class (...) my own thoughts and particular relationships with my environment were not considered or even asked about. That’s part of why this class was so valuable to me.",
  },
  {
    id: 9,
    name: "Participant: 'Returning Home' an socio-ecology and systemic thought workshop series 2023",
    description:
      "I had an opportunity to connect more with the shame and embarrassment that comes from acting outside of normative ways of being as defined by modern society and I had the opportunity to connect with my capacity to meet the biodiverse reality",
  },
];

export default students;
