import Observing from "../assets/Home/observing.png";
import Translating from "../assets/Home/translating.png";
import Embodying from "../assets/Home/embodying.png";

const home = [
  {
    id: 1,
    title: "Observing Evolution",
    description:
      "I’ve always found awe in nature which led me to photography as a hobby and research as a profession. These are my ways of deeply witnessing the wonders of evolution: I capture biodiversity patters with my camera and understand their origins & dynamics through my research.",
    img: Observing,
    button1: "Research",
    button2: "Gallery",
    pictureRight: false,
    link1: "research",
    link2: "gallery",
  },
  {
    id: 2,
    title: "Translating Evolution",
    description:
      "Teaching evolution, in any context, is an act of translation: from DNA to big data, through plots and artistic representations, into stories that engage ALL our senses! I love to go through this holistic process while holding inclusive and creative education spaces for ALL.",
    img: Translating,
    button1: "Outreach",
    button2: "Teaching",
    pictureRight: true,
    link1: "outreach",
    link2: "teaching",
  },
  {
    id: 3,
    title: "Embodying Evolution",
    description:
      "My wonder for evolution roots my study of systemic education approaches to global socio-ecological issues, which I explore through my human experiences and writting. Ecosystems are my biggest teachers of complexity and belonging and I like to share the lessons I learn, as an act of care <3",
    img: Embodying,
    button1: "Blog",
    button2: "Book",
    pictureRight: false,
    link1: "blog",
    link2: "book",
  },
];

export default home;
