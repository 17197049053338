const readers = [
 {
    id: 1,
    name: "Goodreads reader",
    description:
      "Pulled me in from the first page. It was exciting to get to explore so many different forests and be reminded how important reconnecting with our place within nature is for the human soul. Part travel adventure, part coming of age memoir, part science education, and wholly a reminder of what it means to be human and how much we need to reconnect with our place in the forest when modern life pulls us too far away.",
  },
  {
    id: 2,
    name: "Cathie R. Eisen author of 'If not now, when?: Stories from a Singular Life' and 'Where you will find me: A poetic journey into the wilderness'",
    description:
      "Telma Laurentino's story and the poetic way she expresses it transports our minds and our hearts into a mystical adventure. We emerge from the forest of her words inspired, renewed and restored. Her book is a precious gift for us all and a discovery we would otherwise never make.",
  },
   {
    id: 3,
    name: "Goodreads reader",
    description:
      "It makes you wonder about many fantastic, breathtaking encounters you can have with nature and yourself.",
  }, 
  {
    id: 4,
    name: "early reader",
    description:
      "What hooked me the most was the book’s honesty. When you talk about fear and shame my I wanted to read more and more out of empathy; and then the stories of locking eyes with chimps, seeing swallows in the storm and - wtf! - sponges on trees – all awakened curiosity and I wanted to read more and more out of wonder.",
  },
  {
    id: 5,
    name: "early reader",
    description:
      "what joyful experience [reading the book]. I was mesmerized by all those beautiful photos! your book has given me whole new perspective of looking at the woods",
  },
  {
    id: 6,
    name: "early reader",
    description:
      " I fell in love with the forests (...) I am so so grateful that you wrote this book.",
  },
  {
    id: 7,
    name: "early reader",
    description:
      "I have been savoring and sipping your forest book. The time is perfect for it and I love everything about how you have offered your thoughts words and love to this. The love comes off the page at every moment.",
  },
  {
    id: 8,
    name: "Prof. Bree Rosenblum, UCB - author of Global Change Biology: the study of life in a rapidly changing planet",
    description:
      "As Telma walks through the world, wisdom pours in and words pour out. Telma brings the eyes of a scientist, the heart of a magician, and the hands of a master crafter to her work. The result is this beautiful, heartfelt book full of inspiration and the immediacy of love. For anyone that has ever felt lost, lonely, or longing, now you have friends in all of the forests of the world!",
  },
  {
    id: 9,
    name: "Goodreads reader",
    description:
      "Reading this book is like watching a documentary, you get to know other cultures and get immersed in nature. But it is more than that. Going through this book is like going on a trip and seeing the world from someone else's eyes.",
  }, 
];

export default readers;
